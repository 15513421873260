import { LOCALES } from "./locales";

export const messages = {
    [LOCALES.ENGLISH]: {
        // Nav
        submitRequest: 'Submit Request',
        navContacts: 'CONTACTS',
        navLang: 'Language',
        // Main,
        mainHeaderCardMusic: 'Since the emergence of TikTok, we have been successfully running the music promoting campaigns with bloggers.',
        mainHeaderCardCompany: 'We are going to select suitable bloggers and develop the concept of an advertising campaign for your product.',
        mainHeaderCardIdentity: 'We design brand-images, develop websites with an emphasis on solving your business goals.',
        more: 'More',

        advant: 'ADVANTAGES ',
        mainContentCardAdvantTitle: 'OF PARTNERSHIP',
        mainContentCardAdvantText: 'Why do bloggers rely on us?',

        mainContentCardFourTitle: 'For 4 years',
        mainContentCardFourText: 'We have been supporting and developing projects involving influencers',

        mainContentCardIndividTitle: 'Individual',
        mainContentCardIndividText: 'Approach to each request and relevant solutions for goal achievement',

        mainContentCardMoreTitle: 'More than 700',
        mainContentCardMoreText: 'Bloggers in the CIS with the option of individually selecting bloggers from the UAE, China, and India',

        mainContentCardPromptTitle: 'Prompt',
        mainContentCardPromptText: 'Management to achieve goals in the shortest time',

        idea: `IF THERE'S AN IDEA, THERE'S A SOLUTION`,

        // Form
        formTitle: 'QUICK',
        formQuick: ' FEEDBACK ',
        formTitleEnd: [],
        from: 'From',
        fromPlaceholder: 'Company name',
        name: 'Name',
        phone: 'Phone number',
        email: 'Email',
        emailPlaceholder: 'Email',
        additional: 'Additional information',
        formPolicy: 'By clicking the "Submit" button, you accept the ',
        formPolicyEnd: 'privacy policy',
        to: 'To',
        where: [],
        attach: 'Attachments',
        formSubmit: 'Submit',
        formDesc: 'Fill out the form and we can contact you',

        // Footer
        footerPolicy: 'Privacy Policy',
        footerPolicyDesc: '*product of Meta, a recognized extremist organization whose activities are banned in the Russian Federation',
        copyright: '© Copyright 2023, All Rights Reserved',
        contacts: 'CONTACT',
        footerLang: 'LANGUAGE',
        menu: 'MENU',
        social: 'FOLLOW US',
        contactus: 'CONTACT US',

        // Company
        companyTitle: 'BRANDS',
        companyDescOne: 'If you have an idea or conception of advertising campaign: We will select bloggers and integrate advertising of your product on all social media platforms in whichever format.',
        companyDescTwo: 'Our experience has allowed us to identify the main advantages: promptness, high-quality content and audience engagement, low CPM, adherence to technical specifications and deadlines.',
        companyDescThree: 'Only by profitable, personalized, and prompt solution, bloggers advertising capable to exceed expectations and carry out plan of required and expected metrics.',
        companyCardDescLeft: 'Partnership with more than 700 influencers',
        companyCardDescCenter: 'Marketing campaign for CIS, Asian and UAE markets',
        companyCardDescRight: 'More than 60 projects with brands are successfully completed',

        conceptionTitle: 'WE CAN DEVELOP A CONCEPT FOR YOUR ADVERTISING CAMPAIGN FROM IDEATION TO IMPLEMENTATION',
        conceptionDesc: 'Do not know how to prepare a promotional plan and realize bloggers integrations?',

        companyLeftOther: 'With the right approach to testing and analytics, you can create a viral repeating mechanic, as well as get the fastest and most effective result mentions on other sites, which directly affects streaming.',
        companyRightOther: 'Brand — image in the perception of customers, and branding — process to create an image. The visual part helps to properly reveal the underlying meaning of the company. The brand have to be recognizable and the design have to be clear.',

        // Integration 
        integration: 'ADVERTISING CAMPAIGNS ',
        for: 'FOR ',
        connectDesc: 'Leave your request and we will get in touch with you within 1 hour.',
        connectButton: 'Get in touch',

        // OtherServices
        oST: 'OTHER SERVICES',

        // Order
        order: 'Get in touch',

        // Music
        musicTitle: 'MUSICIANS ',
        musicDescOne: 'For more than four years, we have been performing advertising placements for labels, distributors, producers, and artistes.',
        musicDescTwo: 'During this period we have already performed and visited interesting forums and events. Moreover, we successfully cooperated with Western hitmakers and aspiring artistes.',
        musicDescThree: 'We will perform advertising placement at the most affordable CPM, in the shortest timeframe.',
        
        musicCardDescLeft: 'We have successfully completed over 560 music projects',
        musicCardDescCenter: 'Offering competitive CPM rates',
        musicCardDescRight: 'Work in the shortest time',

        musicLeftOther: 'The influence direction for brands solves the important task of reling the target audience. The well-developed strategy and promo plan will appear more likability for to the brand, and also increase its recognition.',
        musicRightOther: 'Brand — image in the perception of customers, and branding — process to create an image. The visual part helps to properly reveal the underlying meaning of the company. The brand have to be recognizable and the design have to be clear.',

        // Identity
        devTitle: 'CORPORATE IDENTITY ',
        devColor: 'DEVELOPMENT ',
        devTitleEnd: 'DESIGN',
        devDescTop: 'Branding includes a complex of work on the visual transformation of the company. Corporate identity not only helps to stand out against of great competition, but also to manage the attention of customers.',
        devDescBot: 'During the branding development process, we immerse in the business, research and analyse the target audience and competitors. We align with the company’s ideology to create an image that helps solve your marketing challenges.',
        devOrderDesc: 'We will create a design, which will make your company standing out of rivals.',

        designTitle: 'UX/UI-DESIGN ',
        designTitleEnd: 'AND WEB-DEVELOPMENT',
        designDescTop: 'A well-designed website increases important metrics: involvement of the audience, time spent on website, and conversion.',
        designDescBot: 'Team of at least four people will work on the project when developing the website: the UX/UI designer, the marketer, the frontend developer, and the backend developer.',
        designOrderDesc: 'We will develop a website that meets all which requirements.',

        subDesignTitle: 'UX/UI-DESIGN',
        subDesignDesc: 'Based on the company identity, user experience and usability, the site architecture is first projected, and then the prototyping and design takes place.',

        subDevTitle: 'WEB DEVELOPMENT',
        subDevDesc: 'Even the most complex idea will not remain without implementation, our team of IT-specialists implements layout of any complexity.',
        
        identityLeftOther: 'The influence direction for brands solves the important task of reling the target audience. The well-developed strategy and promo plan will appear more likability for to the brand, and also increase its recognition.',
        identityRightOther: 'With the right approach to testing and analytics, you can create a viral repeating mechanic, as well as get the fastest and most effective result mentions on other sites, which directly affects streaming.',
    
        // Contacts
        contactsTitle: 'CONTACT US',
        contactsMail: 'MAIL FOR COOPERATION',
        communications: 'COMMUNICATION FACILITIES',

        // QR
        qrTitle: 'SCAN ',
        qrColor: 'QR ',
        qrTitleEnd: '-CODE',
        qrDesc: 'Point your phone camera',
        qrClose: 'Close',

        // Error
        errorBack: 'Go back home',

        //reqField
        reqField: 'THIS FIELD IS REQUIRED'
    },
    [LOCALES.RUSSIAN]: {
        // Nav
        submitRequest: 'Оставить заявку',
        navContacts: 'КОНТАКТЫ',
        navLang: 'Язык',

        // Main,
        mainHeaderCardMusic: 'Успешно продвигаем музыкальные проекты через блогеров с момента появления TikTok.',
        mainHeaderCardCompany: 'Подберем релевантных блогеров, разработаем концепцию рекламной кампании для вашего продукта.',
        mainHeaderCardIdentity: 'Дизайним бренды, разрабатываем сайты с акцентом на решении ваших бизнес задач.',
        more: 'Подробнее',

        advant: 'ПРЕИМУЩЕСТВА ',
        mainContentCardAdvantTitle: 'РАБОТЫ С НАМИ',
        mainContentCardAdvantText: 'Или почему нам доверяют?',

        mainContentCardFourTitle: '4 года',
        mainContentCardFourText: 'Сопровождаем и создаем проекты с участием авторов',

        mainContentCardIndividTitle: 'Индивидуально',
        mainContentCardIndividText: 'Подходим к каждому запросу и подбираем релевантные решения для достижения целей',

        mainContentCardMoreTitle: 'Более 700',
        mainContentCardMoreText: 'Авторов в СНГ с возможностью индивидуального подбора блогеров в ОАЭ, Китае и Индии',

        mainContentCardPromptTitle: 'Оперативный',
        mainContentCardPromptText: 'Менеджмент для достижения поставленных целей в кратчайшие сроки',

        idea: 'ЕСЛИ ЕСТЬ ИДЕЯ, ЗНАЧИТ, ЕСТЬ РЕШЕНИЕ',

        // Form
        formTitle: 'ФОРМА',
        formQuick: ' БЫСТРОЙ ',
        formTitleEnd: 'ОБРАТНОЙ СВЯЗИ',
        from: 'От кого',
        fromPlaceholder: 'Компания',
        name: 'Имя',
        phone: 'Телефон',
        email: 'Откуда',
        emailPlaceholder: 'Email',
        additional: 'Комментарий',
        formPolicy: 'Нажимая кнопку «Отправить», вы принимаете правила ',
        formPolicyEnd: 'политики конфиденциальности',
        to: 'Кому',
        where: 'Куда',
        attach: 'Прикрепить файл',
        formSubmit: 'Отправить заявку',
        formDesc: 'Заполните форму, чтобы мы могли связаться с вами',

        // Footer
        footerPolicy: 'Политика конфиденциальности',
        footerPolicyDesc: '*продукт компании Meta, признанной экстремистской организацией и деятельность которой запрещена в Российской Федерации',
        copyright: '© Copyright 2023, Все права защищены',
        contacts: 'КОНТАКТЫ',
        footerLang: 'ЯЗЫК',
        menu: 'МЕНЮ',
        social: 'СОЦСЕТИ',
        contactus: 'КОНТАКТЫ',

        // Company
        companyTitle: 'БРЕНДОВ',
        companyDescOne: 'Есть идея или концепция рекламной кампании? Подберем блогеров и интегрируем рекламу вашего продукта во всех социальных сетях, на любой площадке и в любом формате.',
        companyDescTwo: 'Опыт работы помог нам выделить основные преимущества: оперативность, высокое качество контента и вовлеченности аудитории у привлекаемых авторов, низкий CPM, соблюдение технического задания и дедлайнов.',
        companyDescThree: 'Только за счет выгодных, персонализированных и оперативных решений, реклама у авторов способна превзойти все ожидания и закрыть необходимый планируемый план по заданным или планируемым метрикам.',
        
        companyCardDescLeft: 'Партнерская сеть из более чем 700 авторов',
        companyCardDescCenter: 'Размещение в СНГ, Азии и ОАЭ',
        companyCardDescRight: 'Реализовано более 60 проектов с брендами',

        conceptionTitle: 'РАЗРАБОТАЕМ КОНЦЕПЦИЮ РЕКЛАМНОЙ КАМПАНИИ ОТ ИДЕИ ДО РЕАЛИЗАЦИИ',
        conceptionDesc: 'Не знаете, как правильно подготовить промо-план и осуществить интеграцию авторов?',
        
        companyLeftOther: 'При правильном подходе к тестированию и аналитике можно создать вирусную повторяющую механику, а также получить максимально быстрый и эффективный результат упоминаний на других площадках, что прямо пропорционально влияет на стриминг.',
        companyRightOther: 'Бренд — образ в голове клиентов, а брендинг —процесс по созданию образа. Визуальная часть помогает правильно раскрыть заложенный смысл компании. Бренд должен быть узнаваемым, а дизайн — понятным.',
        
        // Integration 
        integration: 'ИНТЕГРАЦИИ ',
        for: 'ДЛЯ ',
        connectDesc: 'Оставьте заявку и мы свяжемся с вами в течение 1 часа.',
        connectButton: 'Связаться',

        // OtherServices
        oST: 'ДРУГИЕ УСЛУГИ',

        // Order
        order: 'Заказать',

        // Music
        musicTitle: 'МУЗЫКАНТОВ ',
        musicDescOne: 'Более четырех лет выполняем размещения рекламы для лейблов, дистрибьюторов, продюсеров и артистов.',
        musicDescTwo: 'За этот срок успели выступить и побывать на интересных форумах и мероприятиях, поработать с западными «хитмейкерами» и начинающими артистами.',
        musicDescThree: 'Выполним рекламные размещения по доступному CPM, в утвержденные и сжатые сроки.',
        
        musicCardDescLeft: 'Реализовано более 560 музыкальных проектов',
        musicCardDescCenter: 'Выгодный CPM',
        musicCardDescRight: 'Работа в сжатые сроки',

        musicLeftOther: 'Направление influence для брендов решает важную задачу доверия целевой аудитории. Правильная стратегия и промо-план позволит не только расположить людей к бренду, но и повысить его узнаваемость.',
        musicRightOther: 'Бренд — образ в голове клиентов, а брендинг —процесс по созданию образа. Визуальная часть помогает правильно раскрыть заложенный смысл компании. Бренд должен быть узнаваемым, а дизайн — понятным.',
    
        // Identity
        devTitle: 'РАЗРАБОТКА ',
        devColor: 'ФИРМЕННОГО ',
        devTitleEnd: 'СТИЛЯ',
        devDescTop: 'Айдентика включает в себя комплекс работ по визуальному преображению компании. Фирменный стиль позволяет не только выделяться на фоне большой конкуренции, но и управлять вниманием клиентов.',
        devDescBot: 'В процессе разработки айдентики погружаемся в бизнес, исследуем и анализируем нужную аудиторию и конкурентов, отталкиваемся от идеологии компании и создаем образ, который поможет решить ваши маркетинговые задачи.',
        devOrderDesc: 'Создадим дизайн, который выделит вашу компанию.',

        designTitle: 'UX/UI-ДИЗАЙН ',
        designTitleEnd: 'И WEB-РАЗРАБОТКА',
        designDescTop: 'Хорошо спроектированный сайт позволит повысить важные метрики: глубину просмотра, время на сайте и конверсию.',
        designDescBot: 'При разработке сайта над проектом будет работать команда специалистов минимум из четырех человек: UX/UI-дизайнер, маркетолог, frontend и backend-разработчики.',
        designOrderDesc: 'Разработаем сайт, который будет отвечать необходимым требованиям. ',

        subDesignTitle: 'UX/UI-ДИЗАЙН',
        subDesignDesc: 'Отталкиваясь от айдентики компании, пользовательского опыта и удобства использования первично проецируется архитектура сайта, а затем происходит прототипирование и дизайн.',

        subDevTitle: 'WEB-РАЗРАБОТКА',
        subDevDesc: 'Даже самая сложная идея не останется без реализации, наша команда IT-специалистов реализует верстку любой сложности.',

        identityLeftOther: 'Направление influence для брендов решает важную задачу доверия целевой аудитории. Правильная стратегия и промо-план позволит не только расположить людей к бренду, но и охватить их максимальное количество.',
        identityRightOther: 'При правильном подходе к тестированию и аналитике можно создать вирусную повторяющую механику, а также получить максимально быстрый и эффективный результат упоминаний на других площадках, что прямо пропорционально влияет на стриминг.',
        
        // Contacts
        contactsTitle: 'КОНТАКТЫ',
        contactsMail: 'ПОЧТА ДЛЯ СОТРУДНИЧЕСТВА',
        communications: 'CРЕДСТВА СВЯЗИ',

        // QR
        qrTitle: 'ОТСКАНИРУЙТЕ ',
        qrColor: 'QR ',
        qrTitleEnd: '-CODE',
        qrDesc: 'Наведите камеру телефона',
        qrClose: 'Закрыть',

        // Error
        errorBack: 'На главную',

        //reqField
        reqField: 'ОБЯЗАТЕЛЬНОЕ ПОЛЕ'
    }
}