import '../../fonts/Fonts.css'
import './reqField.css'

import { FormattedMessage } from 'react-intl'

function ReqField () {
    return (
        <p className='reqField'><FormattedMessage id='reqField'/></p>
    )
}

export default ReqField