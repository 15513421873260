import '../../fonts/Fonts.css'
import './soon.css'

function ComingSoon () {
    return (
        <div className='soonContainer'>
            <img src="UI/comingSoon.svg" alt="" />
        </div>
    )
}

export default ComingSoon